module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"JESEDU-Jogja2024","short_name":"JESEDU-Jo...","description":"II COLLOQUIUM JESEDU-Jogja2024 is an international gathering for the leadership teams of our Jesuit schools. ","lang":"en","start_url":"/","background_color":"#262262","theme_color":"#E6656A","display":"standalone","icon":"src/assets/app-icon.png","localize":[{"start_url":"/es/","lang":"es","name":"JESEDU-Jogja2024","short_name":"JESEDU-Jo...","description":"El II Coloquio JESEDU-Jogja2024 es un encuentro internacional para los equipos de liderazgo de nuestras escuelas jesuitas."},{"start_url":"/fr/","lang":"fr","name":"JESEDU-Jogja2024","short_name":"JESEDU-Jo...","description":"Le deuxième colloque du JESEDU-Jogja2024 correspond à un rassemblement international pour les équipes de direction de nos écoles jésuites."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f54beee8758feaf10a2c161e624fefd8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7J7P1CYE75"],"gtagConfig":{"anonymize_ip":false},"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
