// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legals-cookie-policy-js": () => import("./../../../src/pages/legals-cookie-policy.js" /* webpackChunkName: "component---src-pages-legals-cookie-policy-js" */),
  "component---src-pages-legals-privacy-statement-js": () => import("./../../../src/pages/legals-privacy-statement.js" /* webpackChunkName: "component---src-pages-legals-privacy-statement-js" */),
  "component---src-pages-legals-terms-of-use-js": () => import("./../../../src/pages/legals-terms-of-use.js" /* webpackChunkName: "component---src-pages-legals-terms-of-use-js" */),
  "component---src-pages-page-contact-js": () => import("./../../../src/pages/page-contact.js" /* webpackChunkName: "component---src-pages-page-contact-js" */),
  "component---src-pages-page-participants-accommodation-js": () => import("./../../../src/pages/page-participants-accommodation.js" /* webpackChunkName: "component---src-pages-page-participants-accommodation-js" */),
  "component---src-pages-page-participants-pilgrimage-js": () => import("./../../../src/pages/page-participants-pilgrimage.js" /* webpackChunkName: "component---src-pages-page-participants-pilgrimage-js" */),
  "component---src-pages-page-participants-registration-js": () => import("./../../../src/pages/page-participants-registration.js" /* webpackChunkName: "component---src-pages-page-participants-registration-js" */),
  "component---src-pages-page-participants-tour-js": () => import("./../../../src/pages/page-participants-tour.js" /* webpackChunkName: "component---src-pages-page-participants-tour-js" */),
  "component---src-pages-page-programme-js": () => import("./../../../src/pages/page-programme.js" /* webpackChunkName: "component---src-pages-page-programme-js" */),
  "component---src-pages-page-speakers-js": () => import("./../../../src/pages/page-speakers.js" /* webpackChunkName: "component---src-pages-page-speakers-js" */)
}

